import React from "react";
import { BlockInfo, InputField } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import { Form } from "../elements/Form";
import List from "../elements/List";
import { ExpandedCard, PrimaryCard } from "../elements/PrimaryCard";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";
import { FormSection } from "./FormSection";

export interface ExpandableCardGridProps {
  __typename: "StrapiComponentBlocksExpandableCardGrid";
  blockInfo: BlockInfo;
  formTitle: string;
  buttonLabel: string;
  form: InputField[];
  postURL: string;
  cards: {
    description: string;
    title: string;
    banner: {
      file: {
        publicURL: string;
      };
    };
  }[];
}

// Returns a brief version of description which breaks the word atmost at
// a space/whitespace character
function briefDescription(description : string, targetLength : number){
  if (description.length == 0){
    return "";
  }
  let pos : number = 0
  let text = description
  while (pos < description.length){
    let newpos : number = text.search("\\s")
    if (pos + newpos > targetLength || newpos < 0){
      break
    }
    // Include the character we searched for
    newpos = newpos + 1
    pos += newpos
    text = text.substring(newpos)
    console.log(pos, newpos, text.substring(0,20))
  }
  // Subtract last space added
  pos -= 1
  if (pos < targetLength - 50){
    // Sanity check, we don't expect words longer than 50 characters, if su truncate them in the middle
    pos = targetLength
  }
  return description.substring(0, pos)
}

export function ExpandableCardGrid({ section }: { section: ExpandableCardGridProps }) {
  const { blockInfo, cards, formTitle, form, postURL, buttonLabel } = section;
  const [expanded, setExpanded] = React.useState(null);

  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container>
        {blockInfo.title && <SectionTitle>{blockInfo.title}</SectionTitle>}
        {blockInfo.description && <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>}
        {cards.length && !expanded ? (
          <List columnsL={Math.min(cards.length, 3)} gap="2.4rem">
            {cards.map((card, idx) => (
              <div key={idx} style={{ display: "flex", justifyContent: "center" }}>
                <PrimaryCard
                  src={card.banner.file.publicURL}
                  title={card.title}
                  description={briefDescription(card.description, 200)+" ..."}
                  onClick={() => {
                    setExpanded(card);
                  }}
                />
              </div>
            ))}
          </List>
        ) : null}
        {expanded && form && (
          <>
            <ExpandedCard
              title={expanded.title}
              description={expanded.description}
              src={expanded.banner.file.publicURL}
              onClose={() => setExpanded(false)}
            />
            <SectionTitle>{formTitle}</SectionTitle>
            <Form buttonLabel={buttonLabel} fields={form} postURL={postURL} />
          </>
        )}
      </Container>
    </Section>
  );
}
